export const PtwRoutePath = {
  Prefix: "ptw",
  Home: "/",

  // Authentication
  NotFoundError: "/404",
  InternalServerError: "/500",
  Login: "/login",
  ForgotPassword: "/forgot-password",

  // Dashboard
  Dashboard: "/dashboard",
  DashboardApprover: "/dashboard/approver",
  DashboardAuxPolice: "/dashboard/aux-police",

  //Profile
  Profile: "/profile",
  ProfileAccount: "/profileAccount",

  // Application Form
  Section1: "/applicationForm/section1",
  Section1Edit: "/applicationForm/section1Edit",
  Section2: "/applicationForm/section1/:permitId/section2",
  Section2ById: (permitId?: string) =>
    `/applicationForm/section1/${permitId}/section2`,
  Section3: "/applicationForm/:workDetailsId/section3",
  Section3ById: (workDetailsId?: string) =>
    `/applicationForm/${workDetailsId}/section3`,
  Section4: "/applicationForm/:workDetailsId/section4",
  Section4ById: (workDetailsId?: string) =>
    `/applicationForm/${workDetailsId}/section4`,
  Section5: "/applicationForm/:permitId/section5",
  Section5ById: (permitId?: string) => `/applicationForm/${permitId}/section5`,

  // Draf Permit
  DraftPermit: "/draftPermit",
  DraftPermitDetails: "/ptw/draftPermit/:draftPermitId/details",
  DraftPermitById: (draftPermitId?: string) =>
    `/ptw/draftPermit/${draftPermitId}/details`,
  DraftPermitSection2: "/draftPermit/:draftPermitId/section2",
  DraftPermitSection2ById: (draftPermitId?: string) =>
    `/draftPermit/${draftPermitId}/section2`,
  DraftPermitSection3: "/draftPermit/:draftWorkDetailsId/section3",
  DraftPermitSection3ById: (draftWorkDetailsId?: string) =>
    `/draftPermit/${draftWorkDetailsId}/section3`,
  DraftPermitSection4: "/draftPermit/:draftWorkDetailsId/section4",
  DraftPermitSection4ById: (draftWorkDetailsId?: string) =>
    `/draftPermit/${draftWorkDetailsId}/section4`,
  DraftPermitSection5: "/draftPermit/:draftPermitId/section5",
  DraftPermitSection5ById: (draftPermitId?: string) =>
    `/draftPermit/${draftPermitId}/section5`,

  // Pending Permit
  PendingPermit: "/pendingPermit",
  PendingPermitDetail: "/ptw/pendingPermit/:pendingId/details",
  PendingPermitId: (pendingId?: string) =>
    `/ptw/pendingPermit/${pendingId}/details`,

  // Active Permit
  ActivePermit: "/activePermit",
  ActivePermitSummary: "/ptw/activePermit/:activePermitId/summary",
  ActivePermitId: (activePermitId?: string) =>
    `/ptw/activePermit/${activePermitId}/summary`,
  ActivePermitDetails: "/ptw/activePermit/:permitId/details",
  ActivePermitIdDetails: (permitId?: string) =>
    `/ptw/activePermit/${permitId}/details`,
  ActivePermitSafetyForm: "/ptw/activePermit/:activePermitId/safetyform",
  ActivePermitIdSafetyForm: (activePermitId?: string) =>
    `/ptw/activePermit/${activePermitId}/safetyform`,
  ActivePermitPrint: "/ptw/activePermit/:activePermitId/printPermit",
  ActivePermitPrintId: (activePermitId?: string) =>
    `/ptw/activePermit/${activePermitId}/printPermit`,

  // History User
  ApplicationHistory: "/applicationHistory",
  ApplicationHistorySummary:
    "/ptw/applicationHistory/:applicationHistoryId/summary",
  ApplicationHistoryId: (applicationHistoryId?: string) =>
    `/ptw/applicationHistory/${applicationHistoryId}/summary`,
  ApplicationHistoryDetails:
    "/ptw/applicationHistory/:applicationHistoryId/details",
  ApplicationHistoryIdDetails: (applicationHistoryId?: string) =>
    `/ptw/applicationHistory/${applicationHistoryId}/details`,
  ApplicationHistorySafetyForm:
    "/ptw/applicationHistory/:applicationHistoryId/safetyform",
  ApplicationHistoryIdSafetyForm: (applicationHistoryId?: string) =>
    `/ptw/applicationHistory/${applicationHistoryId}/safetyform`,
  ApplicationHistoryPrint:
    "/ptw/applicationHistory/:applicationHistoryId/printPermitHistory",
  ApplicationHistoryPrintId: (applicationHistoryId?: string) =>
    `/ptw/applicationHistory/${applicationHistoryId}/printPermitHistory`,

  PaymentHistory: "/paymentHistory",
  PaymentHistoryPrintReciept:
    "/ptw/paymentHistory/:paymentHistoryId/printReceiptPaymentHistory",
  PaymentHistoryPrintRecieptId: (paymentHistoryId?: string) =>
    `/ptw/paymentHistory/${paymentHistoryId}/printReceiptPaymentHistory`,

  // Manage
  AdminManagement: "/adminManagemant",
  PoManagement: "/projectOwnerManagement",
  AoManagement: "/areaOwnerManagement",
  HseManagement: "/hseManagement",
  ApManagement: "/auxPoliceManagement",
  TowManagement: "/towManagement",
  DowManagement: "/dowManagement",
  LocationManagement: "/locationManagement",

  // Auxiliary Police
  PendingPayment: "/pendingPayment",
  PendingPaymentDetails: "/ptw/pendingPayment/:pendingPaymentId/details",
  PendingPaymentIdDetails: (pendingPaymentId?: string) =>
    `/ptw/pendingPayment/${pendingPaymentId}/details`,
  PendingPaymentSummary: "/ptw/pendingPayment/:pendingPaymentId/summary",
  PendingPaymentIdSummary: (pendingPaymentId?: string) =>
    `/ptw/pendingPayment/${pendingPaymentId}/summary`,
  PendingPaymentPrintReciept: "/ptw/pendingPayment/:pendingPaymentId/print",
  PendingPaymentPrintRecieptId: (pendingPaymentId?: string) =>
    `/ptw/pendingPayment/${pendingPaymentId}/print`,

  HistoryPermitAp: "/historyPermit/ap",
  HistoryPermitSummaryAp: "/ptw/historyPermit/ap/:historyPermitId/summary",
  HistoryPermitIdAp: (historyPermitId?: string) =>
    `/ptw/historyPermit/ap/${historyPermitId}/summary`,
  HistoryPermitDetailsAp: "/ptw/historyPermit/ap/:historyPermitId/details",
  HistoryPermitIdDetailsAp: (historyPermitId?: string) =>
    `/ptw/historyPermit/ap/${historyPermitId}/details`,
  HistoryPermitPrintAp: "/ptw/historyPermit/ap/:historyPermitId/print",
  HistoryPermitPrintIdAp: (historyPermitId?: string) =>
    `/ptw/historyPermit/ap/${historyPermitId}/print`,

  HistoryPayment: "/historyPayment",
  HistoryPaymentSummaryAp: "/ptw/historyPayment/ap/:historyPaymentId/summary",
  HistoryPaymentIdAp: (historyPaymentId?: string) =>
    `/ptw/historyPayment/ap/${historyPaymentId}/summary`,
  HistoryPaymentPrintReciept: "/ptw/historyPayment/:historyPaymentId/print",
  HistoryPaymentPrintRecieptId: (historyPaymentId?: string) =>
    `/ptw/historyPayment/${historyPaymentId}/print`,

  // Approver
  SuspendForm: "/suspendForm",
  ListApplication: "/listofApplication/:locationId",
  ListApplicationId: (locationId?: string) =>
    `/listofApplication/${locationId}`,

  // Pending Approval PO, AO, HSE
  NewPermit: "/newPermit",
  NewPermitSummary: "/newPermit/:newPermitId/summary",
  NewPermitId: (newPermitId?: string) => `/newPermit/${newPermitId}/summary`,

  Extend: "/extend",
  ExtendDetails: "/extend/:extendPermitId/details",
  ExtendPermitSummary: "/extendPermit/:extendPermitId/summary",
  ExtendPermitId: (extendPermitId?: string) =>
    `/extendPermit/${extendPermitId}/summary`,
  ExtendByIdDetails: (extendPermitId?: string) =>
    `/extend/${extendPermitId}/details`,

  CloseDaily: "/closeDaily",
  CloseDailyDetails: "/closeDaily/:closeDailyId/details",
  CloseDailySummary: "/closeDaily/:closeDailyId/summary",
  CloseDailyId: (closeDailyId?: string) =>
    `/closeDaily/${closeDailyId}/summary`,
  CloseDailyByIdDetails: (closeDailyId?: string) =>
    `/closeDaily/${closeDailyId}/details`,

  // Approver Active Permit
  ActivePermitApprover: "/ptw/approver/activePermit",
  ActivePermitApproverSummary:
    "/ptw/approver/activePermit/:activePermitId/summary",
  ActivePermitApproverId: (activePermitId?: string) =>
    `/ptw/approver/activePermit/${activePermitId}/summary`,
  ActivePermitApproverDetails:
    "/ptw/approver/activePermit/:activePermitId/details",
  ActivePermitApproverIdDetails: (activePermitId?: string) =>
    `/ptw/approver/activePermit/${activePermitId}/details`,
  ActivePermitApproverPrint:
    "/ptw/approver/activePermit/:activePermitId/printPermit",
  ActivePermitApproverPrintId: (activePermitId?: string) =>
    `/ptw/approver/activePermit/${activePermitId}/printPermit`,

  Closing: "/closing",
  ClosingReportRegister: "/closing/:closingId/report/register",
  ClosingReportRegisterId: (closingId?: string) =>
    `/closing/${closingId}/report/register`,
  ClosingDetails: "/closing/:closingId/details",
  ClosingByIdDetails: (closingId?: string) => `/closing/${closingId}/details`,
  CompletePermit: "/completePermit",
  CompletePermitSummary: "/completePermit/:completePermitId/summary",
  CompletePermitId: (completePermitId?: string) =>
    `/completePermit/${completePermitId}/summary`,

  HistoryPermit: "/historyPermit",
  HistoryPermitSummary: "/ptw/historyPermit/:historyPermitId/summary",
  HistoryPermitId: (historyPermitId?: string) =>
    `/ptw/historyPermit/${historyPermitId}/summary`,
  HistoryPermitDetails: "/ptw/historyPermit/:historyPermitId/details",
  HistoryPermitIdDetails: (historyPermitId?: string) =>
    `/ptw/historyPermit/${historyPermitId}/details`,
  HistoryPermitSafetyForm: "/ptw/historyPermit/:historyPermitId/safetyform",
  HistoryPermitIdSafetyForm: (historyPermitId?: string) =>
    `/ptw/historyPermit/${historyPermitId}/safetyform`,
  HistoryPermitPrint: "/ptw/historyPermit/:historyPermitId/print",
  HistoryPermitPrintId: (historyPermitId?: string) =>
    `/ptw/historyPermit/${historyPermitId}/print`,
  HistoryPermitPrintListPreview:
    "/ptw/historyPermit/:historyPermitId/print/listpreview",
  HistoryPermitPrintListPreviewId: (historyPermitId?: string) =>
    `/ptw/historyPermit/${historyPermitId}/print/listpreview`,
};
